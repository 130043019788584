import CalMain from './Page/CalMain'

import './App.css'

function App() {
  return (
    <div className="App">
      <CalMain/>
    </div>
  )
}

export default App