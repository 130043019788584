import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import { createStore } from 'redux'
// import rootReducer from './modules'
import { Provider } from 'react-redux'

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

// export const store = createStore(
//   rootReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// ) // 스토어생성 및 리덕스 툴 적용.
// console.log(store.getState()); // 스토어의 상태 확인 .

// const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        {/* <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}> */}
            <App />
          {/* </PersistGate>
        </Provider> */}
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
