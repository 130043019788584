import * as React from "react";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  TextField,
  FormControl,
  Button,
  NativeSelect,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  Container,
  Grid,
} from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let namesTempList = [];
let groupTempList = [];
let resultTempList = [];
let finalMemberList = [];
let finalMemberInfoList = [];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function CalMain() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [nameTemp, setNameTemp] = React.useState();
  const [nameList, setNameList] = React.useState([]);
  const [groupName, setGroupName] = React.useState();
  const [groupTemp, setGroupTemp] = React.useState();
  const [groupList, setGroupList] = React.useState([]);
  const [totalValue, setTotalValue] = React.useState([]);
  const [resultList, setResultList] = React.useState([]);
  const [memberInfo, setMemberInfo] = React.useState([]);

  const [openDialogResultAdd, setOpenDialogResultAdd] = React.useState(false);

  React.useEffect(() => {}, [nameList, groupList]);

  const handleNameTemp = (e) => {
    setNameTemp(e.target.value);
  };
  const handleTotalValue = (e) => {
    setTotalValue(e.target.value.replace(/\D/g, ""));
    // setTotalValue(e.target.value)
  };
  const handleGroupTemp = (e) => {
    setGroupTemp(e.target.value);
  };
  const handleGroupName = (event) => {
    setGroupName(event.target.value);
  };

  const handlePersonChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const AddName = () => {
    if (nameTemp !== undefined && nameTemp !== "" && nameTemp !== null) {
      namesTempList.push(nameTemp);
    }

    const Filtered_namesTempListTemp = namesTempList.reduce((acc, current) => {
      const x = acc.find((item) => item === current);
      if (!x) {
        return acc.concat([current]);
      } else {
        // console.log(current);
        return acc;
      }
    }, []);
    console.log("AddName_nameList:", nameList);
    setNameList(Filtered_namesTempListTemp);
    setNameTemp("");
  };

  const AddGroup = () => {
    if (groupTemp !== undefined && groupTemp !== "" && groupTemp !== null) {
      groupTempList.push(groupTemp);
    }

    const Filtered_groupTempListTemp = groupTempList.reduce((acc, current) => {
      const x = acc.find((item) => item === current);
      if (!x) {
        return acc.concat([current]);
      } else {
        // console.log(current);
        return acc;
      }
    }, []);
    setGroupList(Filtered_groupTempListTemp);
    console.log("AddGroup_groupList:", groupList);
    setGroupTemp("");
  };

  const ResultAdd = () => {
    const findresultGroupName = resultList.findIndex(
      (item) => item.groupName === groupName
    );
    console.log("findresultGroupName:", findresultGroupName);
    console.log("findresultGroupName2", resultList[findresultGroupName]);
    console.log("findresultGroupName3", resultList);
    if (findresultGroupName > -1) {
      alert("이미 완료한 정산 장소네요.");
    } else {
      if (
        groupName === null ||
        groupName === undefined ||
        groupName === "" ||
        totalValue === null ||
        totalValue === undefined ||
        totalValue === "" ||
        totalValue === 0 ||
        personName === null ||
        personName === undefined ||
        personName === ""||
        personName.length ===0
      ) {
        alert("빈칸이 있네요.");
      } else {
        const Object0 = {
          groupName: groupName,
          groupTotalValue: totalValue,
          groupMember: personName,
        };
        setGroupName();
        setTotalValue("");
        setPersonName([]);
        console.log("ResultAdd_Object:", Object0);
        resultTempList.push(Object0);
        const Filtered_resultTempList = resultTempList.reduce(
          (acc, current) => {
            const x = acc.find((item) => item.groupName === current.groupName);
            if (!x) {
              console.log("Filtered_resultTempList_current1", current);

              return acc.concat([current]);
            } else {
              console.log("Filtered_resultTempList_current2", current);
              return acc;
            }
          },
          []
        );
        setResultList(Filtered_resultTempList);
        finalMemberList = [];
        Filtered_resultTempList.map((item1) => {
          const finalValue =
            parseInt(item1.groupTotalValue) /
            parseInt(item1.groupMember.length);
          item1.groupMember.map((item2) => {
            const Object2 = {
              name: item2,
              value: finalValue,
            };

            finalMemberList.push(Object2);
          });
        });
        console.log("ResultAdd_finalMemberList:", finalMemberList);

        // 배열.reduce((누적값, 현잿값, 인덱스, 요소) => { return 결과 }, 초깃값);
        const test1 = finalMemberList.reduce((acc, current) => {
          acc[current.name] = acc[current.name] || [];
          acc[current.name].push(current.value);
          return acc;
        }, {});

        // 위에서 만든 객체를 key로 돌려서 새로운 객체 return
        const test2 = Object.keys(test1).map((key) => {
          return { name: key, value: test1[key] };
        });
        console.log("ResultAdd_test2:", test2);
        finalMemberInfoList = [];
        test2.map((item3) => {
          if (item3.value.length > 1) {
            let sum = 0;
            item3.value.map((item4) => {
              sum += item4;
            });
            const Object3 = {
              name: item3.name,
              value: parseInt(sum),
            };
            finalMemberInfoList.push(Object3);
          } else {
            const Object4 = {
              name: item3.name,
              value: parseInt(item3.value),
            };
            finalMemberInfoList.push(Object4);
          }
        });
        console.log("finalMemberInfoList:", finalMemberInfoList);
        setMemberInfo(finalMemberInfoList);
        handleDialogResultAddClose();
      }
    }
  };
  const handleDialogResultAddOpen = () => {
    setOpenDialogResultAdd(true);
  };
  const handleDialogResultAddClose = () => {
    setOpenDialogResultAdd(false);
  };
  return (
    <div>
      <h1>AWESOME PEOPLE</h1>
      <h3>N분의1 계산기 v1.0.0</h3>
      <div>
        {/* 정산하기 다이얼로그*/}
        <div style={{ marginTop: "30px" }}>
          <Paper sx={{ margin: 1 }}>
            <Dialog
              open={openDialogResultAdd}
              onClose={handleDialogResultAddClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"정산하기"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"정산완료한 장소를 선택하면 정산이 되지 않습니다."}
                </DialogContentText>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    정산 장소 선택
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={groupName || ""}
                    onChange={handleGroupName}
                    autoWidth
                    label="groupName"
                  >
                    {groupList.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                  <TextField
                    type="text"
                    inputProps={{
                      maxLength: 7,
                    }}
                    sx={{ marginTop: 2, width: "100%" }}
                    id="outlined-basic"
                    value={totalValue}
                    label="금액"
                    variant="outlined"
                    onChange={handleTotalValue}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-multiple-chip-label">
                    이름선택
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={personName || ""}
                    onChange={handlePersonChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="인원선택"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {nameList.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogResultAddClose}>취소</Button>
                <Button onClick={ResultAdd} autoFocus>
                  정산하기
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </div>
        <Paper sx={{ margin: 1 }}>
          <Paper sx={{ margin: 1, top: "auto" }}>
            <Typography
              sx={{
                backgroundColor: "#e0e1dd",
                height: 40,
                fontSize: 18,
                fontFamily: "SUIT",
                lineHeight: "26.4px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              참여 명단
            </Typography>
          </Paper>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Paper
                sx={{
                  margin: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <TextField
                  sx={{ margin: 0.8 }}
                  id="outlined-basic"
                  value={nameTemp || ""}
                  label="이름 추가"
                  variant="outlined"
                  onChange={handleNameTemp}
                />
                <Button onClick={AddName} variant="contained">
                  이름 추가
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              {nameList.length > 0 ? (
                nameList.map((name) => (
                  <Paper key={name} sx={{ margin: 1 }}>
                    <Typography>{name}</Typography>
                  </Paper>
                ))
              ) : (
                <Typography
                  sx={{
                    margin: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  정산에 필요한 모든 이름을 한명씩 추가해 주세요.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ margin: 1 }}>
          <Paper sx={{ margin: 1, top: "auto" }}>
            <Typography
              sx={{
                backgroundColor: "#e0e1dd",
                height: 40,
                fontSize: 18,
                fontFamily: "SUIT",
                lineHeight: "26.4px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              정산 장소
            </Typography>
          </Paper>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Paper
                sx={{
                  margin: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <TextField
                  sx={{ margin: 0.8 }}
                  value={groupTemp || ""}
                  label="정산 장소 추가"
                  variant="outlined"
                  onChange={handleGroupTemp}
                />
                <Button variant="contained" onClick={AddGroup}>
                  정산 장소 추가
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              {groupList.length > 0 ? (
                groupList.map((item, index) => {
                  return (
                    <Paper key={index} sx={{ margin: 1 }}>
                      {index + 1}차 장소: {item}
                    </Paper>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    margin: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  정산 장소를 한 곳씩 추가해 주세요.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            margin: 1,
            marginTop: 10,
            height: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handleDialogResultAddOpen}
            sx={{ height: 100, fontSize: 20 }}
          >
            차례로 정산 하기
          </Button>
        </Paper>
        <Paper sx={{ margin: 1 }}>
          <Paper sx={{ margin: 1, top: "auto", marginTop: 5 }}>
            <Typography
              sx={{
                backgroundColor: "#e0e1dd",
                height: 40,
                fontSize: 18,
                fontFamily: "SUIT",
                lineHeight: "26.4px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              장소별 정보
            </Typography>
          </Paper>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              {resultList.length > 0 ? (
                resultList.map((data) => {
                  return (
                    <Paper sx={{ margin: 1 }}>
                      <Typography>
                        장소:&nbsp;{data.groupName}&nbsp; &nbsp;
                        &nbsp;금액:&nbsp;{data.groupTotalValue}&nbsp; &nbsp;
                        &nbsp;인원:
                        {data.groupMember.map((name) => (
                          <span>&nbsp;&nbsp;{name}</span>
                        ))}
                      </Typography>
                    </Paper>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    margin: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  차례로 정산 하기 버튼으로 차례로 정산해 주시면 정보가
                  나옵니다.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ margin: 1 }}>
          <Paper sx={{ margin: 1, top: "auto" }}>
            <Typography
              sx={{
                backgroundColor: "#e0e1dd",
                height: 40,
                fontSize: 18,
                fontFamily: "SUIT",
                lineHeight: "26.4px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              각 인원별 총 합계 금액
            </Typography>
          </Paper>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {memberInfo.length > 0 ? (
                memberInfo.map((data) => {
                  return (
                    <Paper sx={{ margin: 1 }}>
                      <Typography>
                        이름:&nbsp;{data.name}&nbsp; &nbsp; &nbsp;금액:&nbsp;
                        {data.value}&nbsp; &nbsp;
                      </Typography>
                    </Paper>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    margin: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  차례로 정산 하기 버튼으로 차례로 정산해 주시면 정보가
                  나옵니다.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
